import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const HTMLContent = ({ content, className }) => (
  <Measure
    className={className}
    dangerouslySetInnerHTML={{ __html: content }}
  />
);

const Measure = styled.div`
  /* max-width: 32rem; */
`;

const Content = ({ content }) => <Measure>{content}</Measure>;

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string
};

HTMLContent.propTypes = Content.propTypes;

export default Content;

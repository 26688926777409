import React from "react";
import styled, { css } from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { p, py, px, mt } from "styled-components-spacing";
import BackgroundImage from "gatsby-background-image";
import Heading from "../components/Heading";

const Subtext = styled.span`
  display: inline-block;
  background-color: hsla(201, 25%, 19%, 0.9);
  ${mt(3)};
  ${py(1)};
  ${px(2)};
  border-radius: 4px;
  z-index: 1;
  font-weight: 500;
`;

const Overlay = styled.div`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(
      to top,
      hsla(197, 25%, 20%, 1),
      hsla(197, 25%, 20%, 0)
    ),
    radial-gradient(hsla(197, 25%, 20%, 0.8), hsla(197, 25%, 20%, 0));
  pointer-events: none;
`;

const BackgroundHeader = props => (
  <BackgroundImage
    Tag="header"
    fluid={props.imageData}
    className={props.className}
    backgroundColor={`#040e18`}
  >
    <Overlay />
    <Heading
      size={1}
      style={{
        textShadow: "0 2px 4px hsl(197, 25%, 20%)",
        zIndex: "1",
        fontWeight: "100"
      }}
    >
      {props.title}
    </Heading>
    {props.subtext && <Subtext>{props.subtext}</Subtext>}
  </BackgroundImage>
);

const Header = styled(BackgroundHeader)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${props =>
    props.theme && props.theme.colors ? props.theme.colors.blue : "#888"};
  ${props =>
    props.imageData &&
    css`
      background-image: url(${props.image});
      background-size: cover;
      background-position: ${props.position || "center"};
      background-attachment: fixed;
    `};
  max-width: 1920px;
  margin: auto;
  ${p({ mobile: 3, tablet: 4 })};
  height: ${props => (props.size === "big" ? "20rem" : "10rem")};

  ${breakpoint("tablet")`
    height: ${props => (props.size === "big" ? "50vh" : "25vh")};
  `}

  ${breakpoint("desktop")`
    height: ${props => (props.size === "big" ? "75vh" : "40vh")};
    max-height: ${props => (props.size === "big" ? "36rem" : "24rem")};
  `}

  color: #fff;
  text-align: center;
`;

export default Header;
